<template>
  <div class="console-notifications-list">
    <v-card class="mb-7 pt-10 no-border">
      <!-- <v-fab-transition v-if="hasPermission(67)">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="secondary"
              dark
              absolute
              top
              right
              fab
              class="mb-4 me-3"
              v-on="on"
              @click="onCreate()"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ t("tooltip.new") }}</span>
        </v-tooltip>
      </v-fab-transition> -->

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />

        <v-col
          v-if="hasPermission(67)"
          cols="12"
          sm="2"
        >
          <v-btn
            color="secondary"
            block
            @click="onCreate"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ t('tooltip.add') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-card-text>
        <v-row
          v-for="(notification, index) in listTable"
          :key="index"
        >
          <v-col
            cols="12"
            md="12"
          >
            <v-card class="has-border">
              <v-card-title>
                <p class="mb-2 mb-sm-0 font-weight-bold">
                  {{ notification.notification_text }}
                </p>
              </v-card-title>
              <v-card-text>
                <div
                  v-if="notification.html_code"
                  class="mt-4"
                  v-html="notification.html_code"
                ></div>
              </v-card-text>

              <v-card-actions>
                <v-row class="ma-0 mt-3 pa-0 text-xs d-flex justify-space-between">
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    class="text-sm-left text-center px-0 pt-0 pb-3 py-sm-0"
                  >
                    {{ t("notifications.created_on") }} {{ moment(notification.date_str).format('ll') }}
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    class="text-sm-right text-center px-0 py-0 py-sm-0"
                  >
                    <v-chip
                      v-if="notification.status && hasPermission(68)"
                      :color="resolvePaymentStatusVariant(notification.status)"
                      label
                      small
                      :class="`v-chip-light-bg ${resolvePaymentStatusVariant(notification.status)}--text mx-3`"
                    >
                      {{ notification.status_str }}
                    </v-chip>

                    <v-fab-transition v-if="hasPermission(68)">
                      <v-tooltip
                        bottom
                      >
                        <template #activator="{ on: tooltip, attrs }">
                          <v-icon
                            size="20"
                            class="me-2 success--text edit-icon"
                            color="success"
                            :style="'color: #FF4C51 !important;'"
                            v-bind="attrs"
                            v-on="{ ...tooltip }"
                            @click="onEdit(notification)"
                          >
                            {{ icons.mdiPencilOutline }}
                          </v-icon>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                    </v-fab-transition>

                    <v-fab-transition v-if="hasPermission(69)">
                      <v-tooltip
                        bottom
                      >
                        <template #activator="{ on: tooltip, attrs }">
                          <v-icon
                            size="20"
                            class="me-2 error--text delete-icon"
                            color="error"
                            v-bind="attrs"
                            v-on="{ ...tooltip }"
                            @click="onDelete(notification.id)"
                          >
                            {{ icons.mdiDeleteOutline }}
                          </v-icon>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </v-fab-transition>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>

            <!-- <v-alert
              class="ma-0 border-alert"
              :type="notification.notification_type"
              color="white"
              outlined
              :icon="resolveIcon(notification.icon)"
            >
              <p style="font-size: 20px !important; font-weight: bold;">
                {{ notification.notification_text }}
              </p>
              <div
                v-if="notification.html_code"
                class="mt-4"
                v-html="notification.html_code"
              ></div>

              <div class="ma-0 mt-9 text-xs d-flex justify-space-between">
                <div>
                  {{ t("notifications.created_on") }} {{ moment(notification.date_str).format('ll') }}
                </div>

                <div>
                  <v-chip
                    v-if="notification.status && hasPermission(68)"
                    :color="resolvePaymentStatusVariant(notification.status)"
                    label
                    small
                    :class="`v-chip-light-bg ${resolvePaymentStatusVariant(notification.status)}--text mr-3 ml-5`"
                  >
                    {{ notification.status_str }}
                  </v-chip>

                  <v-fab-transition v-if="hasPermission(68)">
                    <v-tooltip
                      bottom
                    >
                      <template #activator="{ on: tooltip, attrs }">
                        <v-icon
                          size="20"
                          class="me-2 success--text edit-icon"
                          color="success"
                          :style="'color: #FF4C51 !important;'"
                          v-bind="attrs"
                          v-on="{ ...tooltip }"
                          @click="onEdit(notification)"
                        >
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </v-fab-transition>

                  <v-fab-transition v-if="hasPermission(69)">
                    <v-tooltip
                      bottom
                    >
                      <template #activator="{ on: tooltip, attrs }">
                        <v-icon
                          size="20"
                          class="me-2 error--text delete-icon"
                          color="error"
                          v-bind="attrs"
                          v-on="{ ...tooltip }"
                          @click="onDelete(notification.id)"
                        >
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </v-fab-transition>
                </div>
              </div>
            </v-alert> -->
          </v-col>
        </v-row>

        <v-card
          v-if="listTable.length === 0"
          outlined
        >
          <v-card-text class="d-flex align-sm-center flex-column flex-sm-row justify-center">
            <div class="mb-3 mr-6 mb-sm-0">
              <div class="text--primary font-weight-medium d-flex align-center justify-center">
                <span class="text-base">{{ t('table.no_data') }}</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPencilOutline,
  mdiCreditCardCheckOutline,
  mdiCreditCardRemoveOutline,
  mdiStar,
} from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { required } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import { error, success } from '@core/utils/toasted'
import usePermissions from '@core/utils/usePermissions'
import useCryptoJs from '@core/utils/useCryptoJs'
import Swal from 'sweetalert2'
import moment from 'moment'

import { deleteConsoleNotification } from '@api'
import useConsoleNotificationsList from './useConsoleNotificationsList'

export default {
  setup() {
    const { t } = useUtils()
    const { hasPermission } = usePermissions()
    const { userData } = useCryptoJs()
    const { router } = useRouter()

    const form = ref(null)
    const filterGroup = ref(null)

    const {
      listTable,
      searchQuery,

      fetchConsoleNotification,
      resolveIcon,
    } = useConsoleNotificationsList()

    const initialData = {
      group_id: null,
      owner_name: null,
      number: null,
      brand: null,
      exp_date: null,
      cvv: null,
      default: 'N',
      type: null,
      billing_name: null,
      billing_address: null,
      billing_email: null,
      billing_phone: null,
      billing_zip_code: null,
    }

    const postData = ref(initialData)

    const resolvePaymentStatusVariant = status => {
      if (status === 'Primary' || status === 'Default' || status === 'A') return 'success'
      if (status === 'Expired' || status === 'I') return 'error'

      return 'secondary'
    }

    const onCreate = () => {
      router.push({
        name: 'views-console-notification-form',
        params: {
          option: 1,
        },
      })
    }

    const onEdit = item => {
      router.push({
        name: 'views-console-notification-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 3,
        },
      })
    }

    const onDelete = async id => {
      Swal.fire({
        title: 'Estas seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await deleteConsoleNotification(id)
          if (response.ok) {
            success(response.message)
            fetchConsoleNotification()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    onMounted(async () => {
      await fetchConsoleNotification()
    })

    return {
      listTable,
      form,
      userData,
      postData,
      searchQuery,

      filterGroup,

      onCreate,
      onEdit,
      onDelete,
      hasPermission,
      resolvePaymentStatusVariant,
      fetchConsoleNotification,
      resolveIcon,

      // validations
      required,

      // i18n
      t,
      moment,

      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPencilOutline,
        mdiCreditCardCheckOutline,
        mdiCreditCardRemoveOutline,
        mdiStar,
      },
    }
  },
}
</script>

<style lang="scss" scope>
  button.v-icon.edit-icon {
    svg {
      color: #56CA00 !important;
    }
  }

  button.v-icon.delete-icon {
    svg {
      color: #FF4C51 !important;
    }
  }

  .no-border {
    border: none !important;
  }

  .has-border {
    border: 1px solid #e7e3fc38 !important;
  }

  .border-alert {
    border: 1px solid rgba(231, 227, 252, 0.22) !important;
  }
</style>
